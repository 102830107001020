.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #161616;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  .splash-screen.hidden {
    opacity: 0;
    pointer-events: none;
  }
  /* Apply the same fade-in effect for both logo and text */
  .splash-logo, .splash-text {
    opacity: 0;
    animation: fadeIn 1.5s ease forwards;
  }
  .splash-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  .splash-text {
    font-family: 'FONTSPRING DEMO - Quiche Sans Medium', sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.03em;
    text-align: center;
    color: white;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    animation-delay: 0.2s; /* Optional delay for the text */
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  .whatsapp-button:hover {
    transform: scale(1.1);
    background-color: #1ebe57;
  }
  