.testimonials-page {
    padding: 40px 20px;
    background-color: #f8f9fa;
}

.speech-bubble {
    position: relative;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.speech-bubble::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #fff;
}

.testimonial-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.testimonial-footer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.testimonial-name {
    margin-left: 15px;
}

.testimonial-name strong {
    color: #333;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
}